import React from 'react'
import { Stars } from '../components/extra/stars'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import 'swiper/css'

import { Fade } from 'react-awesome-reveal'
import reviews from '../data/reviews.json'

export const ReviewSection = ({ title, visibleSlides }) => {
	SwiperCore.use(Autoplay)

	const slides = reviews.map((slide, i) => {
		return (
			<SwiperSlide key={i}>
				<Stars number={slide.rating} />

				<p className="text-xl text-center pb-2">{slide.review}</p>
				<h2 className="text-center text-xl">{'- ' + slide.name}</h2>
			</SwiperSlide>
		)
	})

	return (
		<Fade triggerOnce>
			<div className="container-xl py-16">
				<h1 className="text-center text-5xl md:text-7xl pt-10">
					{title || 'Swiper Section'}
				</h1>
				<hr className="hr-sm mx-auto my-5 border-primary" />
				<div className="flex items-center justify-center pb-5 max-w-4xl m-auto">
					<p className="text-center m-auto text-2xl px-5">
						Nothing warms our hearts more than seeing our clients land huge
						profits & make strides toward financial freedom - Here's what they
						have to say.
					</p>
				</div>
				<div className="p-8">
					<Swiper
						autoplay={{
							delay: 5000,
							disableOnInteraction: false
						}}
						breakpoints={{
							1024: {
								slidesPerView: visibleSlides || 1
							}
						}}
						loop={true}
						spaceBetween={50}
						// onSwiper={swiper => console.log(swiper)}
					>
						{slides}
					</Swiper>
				</div>
			</div>
		</Fade>
	)
}
