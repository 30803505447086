import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'

export function useHouseBGImage() {
	const { houseImg } = useStaticQuery(
		graphql`
			query {
				houseImg: file(relativePath: { eq: "city.jpg" }) {
					childImageSharp {
						gatsbyImageData(width: 1500, placeholder: BLURRED, formats: AUTO)
					}
				}
			}
		`
	)

	const convert = getImage(houseImg)
	const finalBg = convertToBgImage(convert)

	return finalBg
}
