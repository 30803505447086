import React from 'react'
import ReactPlayer from 'react-player/lazy'
import vid from '../videos/doodle.mp4'
import thumb from '../images/doodle-thumb.png'
import { Fade, Slide } from 'react-awesome-reveal'
import scrollTo from 'gatsby-plugin-smoothscroll'
import BackgroundImage from 'gatsby-background-image'
import { useHouseBGImage } from '../hooks/housebg-hook'

export const VideoPlayerSection = ({ title, button }) => {
	let bgImage = useHouseBGImage()
	return (
		<BackgroundImage {...bgImage}>
			<div className="container-xl bg-base-200/90 flex flex-col items-center px-5 py-16">
				<Fade triggerOnce>
					<h1 className="text-center pb-8">{title || 'Placeholder Title'}</h1>
				</Fade>
				<div className="m-auto w-10/12 md:w-6/12">
					<Slide triggerOnce>
						<div className="player-wrapper">
							<ReactPlayer
								className="react-player"
								url={vid}
								controls
								light={thumb}
								width="100%"
								height="100%"
								playIcon={<></>}
							/>
						</div>
					</Slide>
					<Fade triggerOnce>
						<div className="flex items-center justify-center pt-5">
							<button
								className="btn btn-lg btn-primary disabled:bg-primary disabled:opacity-80 disabled:text-opacity-100"
								onClick={() => scrollTo('#join')}>
								{button || 'Placeholder Button'}
							</button>
						</div>
					</Fade>
				</div>
			</div>
		</BackgroundImage>
	)
}
export const VideoPlayer = ({ title, button }) => {
	return (
		<div className="m-auto w-10/12 md:w-6/12">
			<Slide triggerOnce>
				<div className="player-wrapper">
					<ReactPlayer
						className="react-player"
						url={vid}
						controls
						light={thumb}
						width="100%"
						height="100%"
						playIcon={<></>}
					/>
				</div>
			</Slide>
		</div>
	)
}
