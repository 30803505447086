import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { StaticImage } from 'gatsby-plugin-image'

import { Slide, Fade } from 'react-awesome-reveal'

export const BrowserSection = ({ children, title }) => {
	const { sectionBg } = useStaticQuery(
		graphql`
			query {
				sectionBg: file(relativePath: { eq: "bg5.jpg" }) {
					childImageSharp {
						gatsbyImageData(width: 1400, placeholder: BLURRED, formats: AUTO)
					}
				}
			}
		`
	)
	const bgConvert = getImage(sectionBg)
	const sectionBgImg = convertToBgImage(bgConvert)
	return (
		<BackgroundImage {...sectionBgImg}>
			<div className="container-xl bg-base-200 bg-opacity-80 text-center px-3 py-16">
				<div className="flex flex-row items-center gap-8">
					<div className="flex-none py-5 hidden lg:block justify-self-start pl-8">
						<Slide direction="left" triggerOnce="true">
							<div className="mockup-window bg-base-300">
								<div className="flex justify-center bg-base-200">
									<StaticImage
										objectPosition="center top"
										width={550}
										src="../images/trade-chart.jpg"
										alt="Image of a trading chart inside a browser window"></StaticImage>
								</div>
							</div>
						</Slide>
					</div>
					<Fade delay="50" triggerOnce="true">
						<div className="justify-self-start md:px-20">
							<div className="pb-8">
								<h1>{title || 'Default Title'}</h1>
							</div>
							<div className="text-xl">{children}</div>
						</div>
					</Fade>
				</div>
			</div>
		</BackgroundImage>
	)
}
