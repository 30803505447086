import React from 'react'
import { ConvertkitForm } from '../components/form'
import { Fade } from 'react-awesome-reveal'
import { StaticImage } from 'gatsby-plugin-image'

export const CtaForm = () => {
	return (
		<div className="container-xl py-16 bg-accent">
			<Fade triggerOnce>
				<div className="flex flex-col lg:flex-row items-center justify-center w-full gap-14 p-5">
					<div className="text-center max-w-3xl m-auto lg:flex items-center gap-8">
						<div className="flex-none pb-3">
							<div className="indicator">
								<span className="indicator-item badge badge-primary font-bold">
									Free!
								</span>
								<StaticImage
									objectPosition="center top"
									placeholder="blurred"
									objectFit="contain"
									width={200}
									src="../images/ebook-cover.png"
									alt="Image of a Options trade"
								/>
							</div>
						</div>

						<div className="shrink">
							<h2 className="text-5xl pb-3">
								Claim your <strong>free</strong> options trading eBook today
							</h2>
							<p className="text-justify">
								Options trading is by far the fastest way to turn $1,000 into
								$1,000,000. But if you're not reading the signals, trading with
								emotion, and investing at a whim, you'll turn that $1,000 into
								blood-red in no time.
							</p>
							<br />
							<p>
								This free options trading eBook will guide you on your journey
								to becoming a stock options pro. And, of course, a wealthier
								individual with financial freedom!
							</p>
						</div>
					</div>
					<div className="w-full max-w-lg m-auto">
						<h3 className="text-center font-bold w-full text-3xl pb-3">
							Claim Your Free EBook Today!
						</h3>
						<ConvertkitForm />
					</div>
				</div>
			</Fade>
		</div>
	)
}
