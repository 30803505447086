import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'

export const ScrollBtn = ({ scrollId, badge, badgeTxt, btnTxt }) => {
	return (
		<div className="indicator">
			<span
				className={`${
					badge ? '' : 'hidden '
				}indicator-item badge badge-secondary`}>
				{badgeTxt}
			</span>
			<button
				className="btn btn-lg btn-block btn-primary disabled:bg-primary disabled:opacity-80 disabled:text-opacity-100"
				onClick={() => scrollTo(`#${scrollId}`)}>
				{btnTxt || 'Placeholder Button'}
			</button>
		</div>
	)
}
