import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import bgvid from '../videos/chart1.mp4'

const Hero = ({ titleText, bodyText, buttonText }) => {
	const { heroImg } = useStaticQuery(
		graphql`
			query {
				heroImg: file(relativePath: { eq: "charts.jpg" }) {
					childImageSharp {
						gatsbyImageData(width: 1800, placeholder: BLURRED, formats: AUTO)
					}
				}
			}
		`
	)

	const heroConvert = getImage(heroImg)
	const heroBg = convertToBgImage(heroConvert)

	return (
		<BackgroundImage className="bg-top" {...heroBg}>
			<div className="hero min-h-40vh md:min-h-80vh" playbackRate="1.25">
				<div className="hero-overlay bg-opacity-80"></div>
				<div className="text-center hero-content text-neutral-content">
					<div className="max-w-6xl">
						<h1 className="mb-3 md:mb-5 text-6xl md:text-8xl font-bold">
							{titleText}
						</h1>
						<p className="mx-auto mb-5 text-xl md:text-3xl md:max-w-5xl">
							{bodyText}
						</p>
						<button
							href="#my-modal"
							className={`${
								buttonText == null ? 'hidden' : 'btn btn-primary'
							}`}>
							{buttonText}
						</button>
					</div>
				</div>
			</div>
		</BackgroundImage>
	)
}

export const VideoHero = ({ titleText, bodyText, buttonText }) => {
	return (
		<div className="hero min-h-full overflow-hidden">
			<ReactPlayer
				url={bgvid}
				playing
				loop
				muted
				playsinline
				width="100%"
				height="80vh"
			/>
			<div className="hero-overlay bg-opacity-80 z-10"></div>
			<div className="text-center hero-content text-neutral-content z-20">
				<div className="max-w-6xl">
					<h1 className="mb-3 md:mb-5 text-6xl md:text-8xl font-bold">
						{titleText}
					</h1>
					<p className="mx-auto mb-5 text-xl md:text-3xl md:max-w-5xl">
						{bodyText}
					</p>
					{/* <div className="indicator">
						<span className="indicator-item badge badge-secondary">
							Limited Offer
						</span>
						<button
							className="btn btn-lg btn-block btn-primary disabled:bg-primary disabled:opacity-80 disabled:text-opacity-100"
							onClick={() => scrollTo('#join')}>
							Join for just
							<span className="line-through decoration-4 decoration-red-500">
								&nbsp;$99&nbsp;
							</span>
							$69!
						</button>
					</div> */}
				</div>
			</div>
		</div>
	)
}

export default Hero
