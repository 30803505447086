import React from 'react'
import PropTypes from 'prop-types'

import { FaStar } from 'react-icons/fa'

export const Stars = ({ number }) => {
	const makeStars = Array(number)
		.fill('star')
		.map((item, i) => {
			return <FaStar className="text-yellow-500" size={35} key={i} />
		})

	// return <div className="flex gap-4">{makeStars}</div>
	return <div className="flex p-5 gap-4 justify-center">{makeStars}</div>
}

Stars.defaultProps = {
	number: 5
}

Stars.propTypes = {
	number: PropTypes.number.isRequired
}
