import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Fade, Slide } from 'react-awesome-reveal'
import { FcAlarmClock, FcBullish, FcCalendar } from 'react-icons/fc'
import { BGSection } from '../components/bgsection'
import { BrowserSection } from '../components/browsersection'
import { ImgCard, TradeCard } from '../components/card'
import { CtaForm } from '../components/cta-form'
import { ScrollBtn } from '../components/extra/scrollto-btn'
import { VideoHero } from '../components/hero'
import Layout from '../components/layout'
import {
	BannerSection,
	LoadingSection,
	ProductSection,
	Section
} from '../components/section'
import Seo from '../components/seo'
import { ReviewSection } from '../components/swiper'
import { VideoPlayerSection } from '../components/video-player'

const IndexPage = () => {
	return (
		<Layout>
			<Seo
				title="Premium Options Alerts"
				description="Discover the best options trading alerts, including day trading alerts and swing trading alerts, with Rapid Fire Options. The right alert can make a trade. The wrong one can cost you money. Join Rapid Fire Options Today!"
			/>
			<VideoHero
				titleText="Rapid Fire Options"
				bodyText="Let our team send you their ideas with the exact strike and expiration date at the beginning and end of each trading day. Specially focused on small to mid-sized accounts. We do the research so you can focus on trading! See our recent big winners below."
			/>

			{/* About us section */}
			<div className="container-xl bg-base flex justify-center py-16">
				<div>
					<h1 className="pt-10 text-7xl text-center">
						Due Diligence? Leave it to us.
					</h1>
					<Fade triggerOnce cascade>
						<div className="text-2xl p-10 text-center m-auto max-w-6xl">
							<p>
								Improve your option trading performance with trading ideas from
								expert options traders. We do the hard work; you reap the
								rewards.
							</p>
							<br />
						</div>
						<div className="flex flex-col lg:flex-row gap-8 justify-center items-center lg:items-stretch max-w-full py-10">
							<Slide direction="up" duration="400" triggerOnce cascade>
								<ImgCard
									cardTitle="Daily Trading Ideas"
									cardBody="Save heaps of wasted time & money with daily trading ideas on calls & puts (adapts to whether the stock market is up or down).">
									<FcCalendar size={100}></FcCalendar>
								</ImgCard>
								<ImgCard
									cardTitle="Big Winners & Track Record"
									cardBody="With recent wins of up to 2,450% (take a look below), we have a proven track record of going above and beyond beating the market.">
									<FcBullish size={100}></FcBullish>
								</ImgCard>
								<ImgCard
									cardTitle="Set Up Within Minutes"
									cardBody="Sign up today & you'll be receiving trading ideas within minutes (simply download Telegram, and you're ready to trade!).">
									<FcAlarmClock size={100}></FcAlarmClock>
								</ImgCard>
							</Slide>
						</div>
					</Fade>
				</div>
			</div>

			<BGSection sectionTitle="How it Works.">
				<div className="pt-10 m-auto">
					<Fade triggerOnce>
						<div className="flex flex-col gap-8 items-center md:items-stretch w-9/12 m-auto">
							<p className="m-auto">
								It's simple. In just three quick steps, you'll be ready to trade
								like a pro without spending hours reading complex whitepapers &
								staring at trading views looking for 'technical analysis'
								signals!
							</p>
							<div className="m-auto max-w-6xl">
								<h2 className="text-4xl">
									1). Limited Time Only - Get a Free 7 day trial
								</h2>
								<p>
									Whether you're ready to go all-in or you'd like to test our
									trading ideas, we have a package for you that won't break the
									bank - And you can cancel anytime... No questions asked!
								</p>
							</div>
							<div className="m-auto max-w-6xl">
								<h2 className="text-4xl">
									2). Download Telegram and Join our Community
								</h2>
								<p>
									Don't worry. Telegram is 100% Free on the Apple and Google
									Play Stores. After downloading & signing up, you'll receive an
									invite to our Telegram chat, where you'll receive daily
									trading ideas that have the best chance of being successful.
								</p>
							</div>
							<div className="m-auto max-w-6xl">
								<h2 className="text-4xl">
									3). Sit Back & Follow Our Trade Ideas
								</h2>
								<p>
									You're now trade-ready! You'll receive options ideas from the
									minute the stock market opens. We have a proven track record
									with some unmatched returns (upwards of 2,450% returns!)
								</p>
							</div>
						</div>
						<div className="text-center pt-10">
							<ScrollBtn scrollId="join" btnTxt="Sign Me Up" />
						</div>
					</Fade>
				</div>
			</BGSection>

			<div className="container-xl bg-accent py-16">
				<Fade triggerOnce>
					<h1 className="text-center text-7xl pb-8 lg:px-5">
						Check out our recent winners, traded in real accounts. Try it out
						for yourself with a free trial!
					</h1>
					<div className="flex flex-row flex-wrap gap-8 items-center justify-center">
						<div className="flex flex-col flex-wrap gap-8 justify-center items-center">
							<TradeCard profit="230">
								<StaticImage
									objectPosition="center top"
									placeholder="blurred"
									objectFit="contain"
									width={350}
									src="../images/trade-1.png"
									alt="Image of a Options trade"
								/>
							</TradeCard>
							<TradeCard profit="440">
								<StaticImage
									objectPosition="center top"
									placeholder="blurred"
									objectFit="contain"
									width={350}
									src="../images/trade-2.png"
									alt="Image of a Options trade"
								/>
							</TradeCard>
						</div>
						<div className="flex flex-col flex-wrap gap-8 justify-center items-center">
							<TradeCard profit="2450">
								<StaticImage
									objectPosition="center top"
									placeholder="blurred"
									objectFit="contain"
									width={350}
									src="../images/trade-3.png"
									alt="Image of a Options trade"
								/>
							</TradeCard>
							<TradeCard profit="269">
								<StaticImage
									objectPosition="center top"
									placeholder="blurred"
									objectFit="contain"
									width={350}
									src="../images/trade-6.png"
									alt="Image of a Options trade"
								/>
							</TradeCard>
						</div>
					</div>
					<div className="text-center text-sm	italic pt-10">
						*past performance is not indicative of future performance
					</div>
				</Fade>
			</div>

			<ReviewSection title="Our Happy Subscribers!" />

			<VideoPlayerSection
				title="Check out this short 1-minute video!"
				button="Click here to join!"
			/>

			<LoadingSection>
				We have our finger on the
				<span className="text-primary font-bold animate-pulse"> PULSE</span> of
				the markets and are constantly updating our stock options screener..
			</LoadingSection>

			<Section sectionTitle="A little bit about us (and how you could be a more successful option trader)">
				<Fade triggerOnce>
					<div className="flex flex-col flex-wrap gap-8 justify-center items-center text-justify px-5">
						<p>
							At Rapid Fire Options, we're a team of expert analysts & options
							traders. With a combined 25-years of experience, we've helped our
							subscribers improve their option trading and be in the best
							position to potentially multiply their portfolios with little to
							no effort on their side.
						</p>
						<p>
							We know how the market works, and we can read the signals.
							Obviously, trading is never risk-free. But with the knowledge &
							experience we share, you can rest easy knowing your option trading
							has the best chance of success with our unique option trading
							ideas.
						</p>
					</div>
				</Fade>
			</Section>

			<BrowserSection title="Gain market clarity and insights with the help of our premium subscription service and our Golden Rules! You risk nothing with our free 7 day trial!">
				<h2 className="text-4xl pb-3">
					There's a reason our subscribers keep renewing month after month!
				</h2>
				<p>
					It's time to stop risking your money with half-hearted advice from
					YouTubers & influencers that are lining their own pockets by
					misinforming retail investors like yourself.
				</p>
				<br />
				<p>
					Sign up today, be sure to follow our daily trading ideas, and watch
					your trading success grow. And it takes ZERO effort from you!
				</p>
			</BrowserSection>

			<CtaForm />

			<ProductSection />

			<BannerSection accent="true">
				<Fade triggerOnce>
					<p>
						Everything you need.
						<br />
						<span className="text-primary">
							To invest the way you want.&nbsp;
						</span>
					</p>
				</Fade>
			</BannerSection>
		</Layout>
	)
}
export default IndexPage
